import { Component } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { IMenuRoutes } from '@layout/menu-routes.interface';
import { provideIcons } from '@ng-icons/core';
import {
  saxCalendarBulk,
  saxChartBulk,
  saxPeopleBulk,
  saxClock1Bulk,
} from '@ng-icons/iconsax/bulk';
import {
  IPageItem,
  PagesListComponent,
} from '@shared/components/pages-list/pages-list.component';
import { RESOURCE_PERMISSION } from '@shared/role-bace-acces-controller/resource-permission.constant';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  standalone: true,
  imports: [IonContent, PagesListComponent],
  viewProviders: [
    provideIcons({
      saxCalendarBulk,
      saxChartBulk,
      saxPeopleBulk,
      saxClock1Bulk,
    }),
  ],
})
export class SettingsPage {
  listings = settingsPageMenuItems();
}

export function settingsPageMenuItems(): Array<IMenuRoutes> {
  return [
    {
      iconName: 'saxCalendarBulk',
      titleI18nKey: 'global.academic_enrolment.title',
      path: '/academic-years',
      permissions: getAcademicYearSettingPermission(),
    },
    {
      iconName: 'saxPeopleBulk',
      titleI18nKey: 'roles_permissions.roles_and_permissions.title',
      path: '/roles',
      permissions: getRoleAndPermissionSettingPermission(),
    },
    {
      iconName: 'saxChartBulk',
      titleI18nKey:
        'support_ticket.configure_escalation_levels_by_ticket_types.title',
      path: '/settings/configure-escalation',
      permissions: getEscalationSettingPermission(),
    },
    {
      iconName: 'saxClock1Bulk',
      titleI18nKey: 'time_period.time_periods.title',
      path: '/settings/time-periods',
      permissions: getTimePeriodsPermission(),
    },
    // {
    //   iconName: 'saxChartBulk',
    //   titleI18nKey: 'attendance.attendance_settings.title',
    //   path: '/settings/attendance-setting',
    //   permissions: getAttendanceSettingPermission(),
    // },
  ];
}

function getAcademicYearSettingPermission() {
  const {
    academicYearCreate,
    academicYearDetailView,
    academicYearDelete,
    academicYearUpdate,
  } = RESOURCE_PERMISSION.academicYear;
  return [
    academicYearCreate,
    academicYearDetailView,
    academicYearDelete,
    academicYearUpdate,
  ];
}

function getRoleAndPermissionSettingPermission() {
  const { viewRoleDetail, createRole, updateRole, deleteRole } =
    RESOURCE_PERMISSION.rolesAndPermission;
  return [viewRoleDetail, createRole, updateRole, deleteRole];
}

function getEscalationSettingPermission() {
  return [RESOURCE_PERMISSION.supportTicket.viewEscalationList];
}

function getAttendanceSettingPermission() {
  return [RESOURCE_PERMISSION.attendance.VIEW_GLOBAL_END_TIME];
}

function getTimePeriodsPermission() {
  return [RESOURCE_PERMISSION.TIME_PERIODS.READ.VIEW_TIME_PERIODS_LIST];
}

export function settingPermissions() {
  return [
    ...getRoleAndPermissionSettingPermission(),
    ...getAcademicYearSettingPermission(),
    ...getEscalationSettingPermission(),
    ...getAttendanceSettingPermission(),
  ];
}
